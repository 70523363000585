/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';

export default function SeoPage(props) {
    const {children} = props
    // const { pages } = useSelector((state)=>state?.allReducers);
    const dispatch = useDispatch();
    // console.log("props", props)

    // useEffect(() => {
    //     if(pages === undefined){
    //         dispatch(CallSetPageTitle("Welcome - UrbanBird"))
    //     }
    // }, [pages]);

    useEffect(() => {
        if(props?.item?.Title){
            document.title = props?.item?.Title;
        }
    }, [props?.item?.Title]);
  return (
      <React.Fragment>
        {children}
      </React.Fragment>
  )
}
